import { Link } from "gatsby"
import React from "react"
import logo from "../images/tetleys-coaches-logo.png"
import Nav from "./nav"

class Header extends React.Component {
  render() {
    return (<header>
      <div className="header">
        <div className="header-body">
          <div className="logo-container">
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <img className="logo" src={logo} alt="Tetley's Coach Hire Leeds" />
            </Link>
          </div>
          <div className="head-contact">
            <Nav />
          </div>
        </div>
      </div>
    </header>)
  }
}

export default Header
