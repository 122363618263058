import { Link } from "gatsby"
import React from "react"
import arrowSvg from "../images/arrow-circle-right-solid.svg"

const Nav = () => (
  <div className="nav">
    <div className="nav__container">
        <ul className="nav__list">
            <li className="nav__item">
                <Link className="nav__item-link" to="/">Home</Link>
            </li>
            <li className="nav__item">
                <Link className="nav__item-link" to="/coach-hire-services/">Services</Link>
            </li>
            <li className="nav__item">
                <Link className="nav__item-link" to="/fleet/">Fleet</Link>
            </li>
            <li className="nav__item">
                <Link className="nav__item-link" to="/school-services/">Schools</Link>
            </li>
            <li className="nav__item">
                <Link className="nav__item-link" to="/jobs/">Jobs</Link>
            </li>
            <li className="nav__item">
                <Link className="nav__item-link" to="/contact/">Contact</Link>
            </li>
            <li className="nav__item">
                <a className="nav__item-link" href="https://portal.tetleyscoaches.co.uk/account">My Account</a>
            </li>
        </ul>
        <div className="nav__quote">
            <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                <span className='btn-quote__text'>Get quote</span>
                <img className='btn-quote__icon' src={arrowSvg} alt=""/>
            </a>
        </div>
    </div>
  </div>
)

export default Nav