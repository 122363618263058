import { Link } from "gatsby"
import React from "react"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import { slide as Menu } from 'react-burger-menu'
import emailSvg from "../images/envelope-regular.svg"
import phoneSvg from "../images/phone-solid.svg"

class MobileNav extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
        this.isMenuOpen = this.isMenuOpen.bind(this);
    }

    toggleMenu() {
        this.setState({ isOpen: !this.state.isOpen});
    }

    isMenuOpen(state) {
        this.setState({ isOpen: state.isOpen});
        return state.isOpen;
    };

    render() {
        return (
            <Menu right isOpen={this.state.isOpen} onStateChange={ this.isMenuOpen } >
                <div className="menu-quote" style={{ outline: 0 }}>
                <h4>Quote</h4>
                <a className="menu-item" href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer"><img src={arrowSvg} alt="Online" /> <span>Online quote</span></a>
                <a className="menu-item" href="tel:+441132762276"><img src={phoneSvg} alt="Phone" /> <span>0113 276 2276</span></a>
                <a className="menu-item" href="mailto:sales@tetleyscoaches.co.uk"><img src={emailSvg} alt="Email" /> <span>sales@tetleyscoaches.co.uk</span></a>
                </div>

                <h4>Menu</h4>
                <Link onClick={this.toggleMenu} className="menu-item" to="/">Home</Link>
                <Link onClick={this.toggleMenu} className="menu-item" to="/coach-hire-services/">Services</Link>
                <Link onClick={this.toggleMenu} className="menu-item" to="/fleet/">Fleet</Link>
                <Link onClick={this.toggleMenu} className="menu-item" to="/school-services/">Schools</Link>
                <Link onClick={this.toggleMenu} className="menu-item" to="/jobs/">Jobs</Link>
                <Link onClick={this.toggleMenu} className="menu-item" to="/contact/">Contact</Link>
                <a className="menu-item"  href="https://portal.tetleyscoaches.co.uk/account">My Account</a>
            </Menu>
        );
    }
}

export default MobileNav